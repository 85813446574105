.footer { 
  margin-top:24px;
  background-color: #1f2328;
  font-size:14px;
  padding:8px 0;
  text-align:right;
  animation: fadeIn 1s; -webkit-animation: fadeIn 1s; -moz-animation: fadeIn 1s; -o-animation: fadeIn 1s; -ms-animation: fadeIn 1s; }
@keyframes fadeIn { 0% {opacity:0;}  80% {opacity:0;} 100% {opacity:1;} }
@-moz-keyframes fadeIn { 0% {opacity:0;}  80% {opacity:0;} 100% {opacity:1;} }
@-webkit-keyframes fadeIn { 0% {opacity:0;}  80% {opacity:0;} 100% {opacity:1;} }
@-o-keyframes fadeIn { 0% {opacity:0;}  80% {opacity:0;} 100% {opacity:1;} }
@-ms-keyframes fadeIn { 0% {opacity:0;}  80% {opacity:0;} 100% {opacity:1;} }

.footer a { margin-left:8px; }