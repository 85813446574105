.topbar { 
  position: fixed; background-color: #1f2329; width: 100%; top: 0px;
  height: 48px; border-bottom: 1px solid black; z-index:101;
}
.logo {  padding-top:6px; }

.xlarge, .large, .medium, .small,.xsmall, .tiny { 
  margin:0 auto; display:grid; grid-template-columns: 40px 1fr 40px 1fr 56px 1fr 300px; 
}
.tiny { margin:0 auto; display:grid; grid-template-columns: 40px 0 1fr 56px 1fr 180px; }

@media screen and (max-device-width: 823px) and (orientation:landscape) { .topbar { position:relative; } }

.tierlist { width:44px; font-size:10px; text-align:center;}
.lang { width:56px; }