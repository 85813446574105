
.wrapper_xlarge, .wrapper_large, .wrapper_medium, .wrapper_small, .wrapper_xsmall {
  margin:0 auto;
}

.wrapper_xlarge {width:1280px; }
.wrapper_large { width: 1038px; }
.wrapper_medium { width: 970px; }
.wrapper_small { width:728px; }
.wrapper_xsmall, .wrapper_tiny { margin:0 10px; }
