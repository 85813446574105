.search {
  text-align: right;
}
.box,
.boxmobile {
  padding-left: 34px;
  font-size: 18px;
  width: 300px;
  font-family: Roboto, Arial, sans-serif;
  height: 40px;
  border-radius: 20px;
  margin-top: 4px;
}

input.box,
input.boxmobile {
  outline: none;
  border: 0;
}

.boxmobile {
  width: 180px;
}

.label {
  position: relative;
}
.label:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 1000 1000' fill-rule='evenodd'%3E%3Cpath d='M 682 426 C 682 92.12 181 97 181 426 C 181 754 682 760 681.45 426 L 682 426 L 682 426 Z M 947 841 C 1022 911 910 1014 846 942 L 655 751 C 157 987 -144 420 173 143 C 541 -180 1012 314 756 650 L 947 841 L 947 841 Z'%3E%3C/path%3E%3C/svg%3E")
    center / contain no-repeat;
}

.results {
  margin-top: 4px;
  margin-left: 0px;
  background-color: white;
  color: #111;
  border: 1px solid #888;
  overflow: hidden;
  z-index: 99999;
}

.row a {
  color: #111;
}
.row a:hover {
  color: #111;
}
.row > div:first-child {
  margin: 2px 2px 2px 2px;
  border-radius: 4px;
}
.row > div:first-child a {
  border-radius: 4px;
}
.row > div:first-child a > div {
  padding: 6px 2px;
  line-height: 32px;
  display: flex;
}

.row a > div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.summoner > div:first-child {
  width: auto;
  margin-top: 3px;
  margin-right: 3px;
  height: 24px;
  border-width: 2px;
  border-style: solid;
  margin-right: 5px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 0px 3px;
  line-height: 22px;
}
